<template>
  <div class="level_index level_indexI">
    <div class="search">
      <el-form :inline="true" :model="search">
        <el-form-item prop="title">
          <el-input
            clearable
            @keydown.enter.native="searchList"
            accesskey=""
            v-model="searchtitle"
            placeholder="请输入组织机构名称"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchList()">
            <i class="fa fa-search" aria-hidden="true"></i>
            查询
          </el-button>

          <el-button type="primary" @click="toSearch" v-if="getPurviewNew('sys:dep:add')">
            <i class="fa fa-plus" aria-hidden="true"></i>
            添加机构
          </el-button>

          <!-- <el-button class="block" @click="del">批量删除</el-button> -->

          <el-button
            v-show="expandKeys"
            v-if="getPurviewNew('sys:dep:expand')"
            type="primary"
            @click="toggleRowExpansion(true)"
          >
            全部展开
          </el-button>

          <el-button
            v-show="!expandKeys"
            v-if="getPurviewNew('sys:dep:collapse')"
            type="danger"
            @click="toggleRowExpansion(false)"
          >
            全部折叠
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- <Fanfu-Table class="FanfuTableBox" v-model="select" :data="data" :search-height="50" :loading="loading"
            :get-list="getList" stripe border ref="FanfuTable"> -->

    <!-- height="100%" -->
    <el-table
      class="el-tableII FanfuTableBox"
      :data="data.list"
      stripe
      border
      ref="multipleTable"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      row-key="id"
    >
      <!-- :expand-row-keys="expandKeys" -->
      <!-- @expand-change="expandChange" -->

      <el-table-column show-overflow-tooltip type="selection" min-width="40" align="center" fixed="left" />
      <el-table-column show-overflow-tooltip label="序号" type="index" align="center" fixed="left" />

      <!-- <el-table-column show-overflow-tooltip prop="id" label="ID" align="left" min-width="80" /> -->

      <el-table-column show-overflow-tooltip label="单位名称" align="left" min-width="250">
        <template slot-scope="scope">
          <span type="expand">
            <i class="fa fa-cube" aria-hidden="true" v-if="scope.row.type == 1"></i>
            <i class="fa fa-database" aria-hidden="true" v-if="scope.row.type == 2"></i>
            {{ scope.row.name }}</span
          >
        </template>
      </el-table-column>

      <el-table-column show-overflow-tooltip prop="CompanyType" label="类型" align="center" min-width="72">
        <template slot-scope="scope">
          <el-button type="primary" round v-if="scope.row.type == 1" size="mini">公司</el-button>
          <el-button type="success" round v-if="scope.row.type == 2" size="mini">部门</el-button>
        </template>
      </el-table-column>

      <el-table-column show-overflow-tooltip prop="state" label="状态" align="left" width="90">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            :active-value="1"
            active-color="#5BD995"
            active-text="正常"
            :inactive-value="2"
            inactive-color="#D9D9D9"
            inactive-text="禁用"
            class="switchSelfBox"
            style="pointer-events: painted"
            @change="switchStatus(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>

      <el-table-column show-overflow-tooltip prop="sort" label="排序" align="center" min-width="70" />

      <el-table-column show-overflow-tooltip prop="create_name" label="创建人" align="left" min-width="150" />

      <el-table-column show-overflow-tooltip prop="term_start_time" label="会员开通时间" align="left" min-width="150" />
      <el-table-column show-overflow-tooltip prop="term_end_time" label="会员到期时间" align="left" min-width="150" />

      <el-table-column show-overflow-tooltip prop="create_time" label="创建时间" align="left" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.create_time != 0">{{ timestampToTime(scope.row.create_time, 2) }}</span>
        </template>
      </el-table-column>

      <el-table-column show-overflow-tooltip prop="update_time" label="更新时间" align="left" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.update_time != 0">{{ timestampToTime(scope.row.update_time, 2) }}</span>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="操作"
        :width="autoTableWidth(['sys:dep:addz', 'sys:dep:edit', 'sys:dep:drop'], 75)"
        align="center"
      >
        <template slot-scope="scope">
          <div class="operate">
            <!-- <el-button @click="toSearchChild(scope.row)" v-if="getPurviewNew('sys:dep:addz')">
                            添加
                        </el-button> -->

            <el-button
              @click="toSearchChild(scope.row)"
              v-if="getPurviewNew('sys:dep:addz') && scope.row.has_child == 1"
            >
              添加
            </el-button>

            <el-button type="primary" @click="edit(scope.row)" v-if="getPurviewNew('sys:dep:edit')"> 编辑 </el-button>
            <el-button type="danger" @click="del(scope.row)" v-if="getPurviewNew('sys:dep:drop')"> 删除 </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- </Fanfu-Table> -->

    <!--  <el-pagination layout="total, prev, pager, next, jumper" background :current-page.sync="data.page"
            :page-size="data.size" :total="data.total" @current-change="handleCurrentChange" /> -->

    <Search ref="searchRef" v-model="search" @refresh="getList(data.size)" />
  </div>
</template>

<script>
import Search from './components/HospitalSearch'

import resizeDetector from 'element-resize-detector'

export default {
  components: {
    Search
  },
  data() {
    return {
      search: {
        title: '',
        name2: '',
        name3: '',
        name4: '',
        name5: '',
        name6: ''
      },

      loading: false,

      select: [],

      data: {
        list: [],
        page: 1,
        size: 15,
        total: 0
      },

      searchtitle: '',

      expandKeys: true
    }
  },

  mounted() {
    this.modifyLayout()
  },

  created() {
    this.modifyLayout()

    this.getList()
  },

  watch: {
    searchtitle(newValue, oldValue) {
      if (oldValue.length > 0 && newValue.length == 0) {
        this.searchList()
      }
    }
  },

  methods: {
    toggleRowExpansion(val) {
      this.expandKeys = !this.expandKeys
      this.formatTree(this.data.list, 0)
    },

    formatTree(tree, val, val1) {
      for (const node of tree) {
        if (this.expandKeys) {
          this.$refs.multipleTable.toggleRowExpansion(node, false)
        } else {
          this.$refs.multipleTable.toggleRowExpansion(node, true)
        }

        if (node.children && node.children.length) {
          this.formatTree(node.children, val + 1)
        }
      }
    },

    expandChange(row, expandRow) {
      console.log(row, expandRow)
    },

    // AllTotal() {
    //     let a = list.length;
    //     for (let i = 0; i < list.length; i++) {
    //         var dic = list[i];
    //         if (dic.hasOwnProperty("children")) {
    //             a = a + dic.children.length
    //         }
    //     }
    //     return a;
    // },

    // getList(size) {
    //     if (size) {
    //         this.data.page = 1
    //         this.data.size = size
    //     }
    //     this.loading = true
    //     this.select = []
    //     this.$nextTick(() => {
    //         const start = this.data.size * (this.data.page - 1)
    //         const end = start + this.data.size
    //         this.data.total = list.length
    //         const data = []
    //         for (let i = start; i < end; i++) {
    //             if (list[i]) {
    //                 data.push(list[i])
    //             }
    //         }
    //         this.data.list = data
    //         setTimeout(() => {
    //             this.loading = false
    //         }, 1000)
    //         setTimeout(() => {
    //             this.modifyLayout();
    //         }, 1100)
    //     })
    // },

    getList() {
      this.$http
        .get('/api/dep/tree', {
          name: this.searchtitle
          // page_size: this.data.size,
          // page: this.data.page
        })
        .then((res) => {
          console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', res)

          if (res.code == 200) {
            this.data.list = res.data
            // this.data.total = res.data.total

            setTimeout(() => {
              this.modifyLayout()
            }, 1100)
          }

          if (res.code != 200) {
            //请求错误
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    searchList() {
      this.getList()
    },

    view(row) {
      console.log(row)
    },

    edit(row) {
      console.log(row)

      this.$refs.searchRef.edit(row)
    },

    switchStatus(row) {
      // console.log(row);

      // return

      let a = row.status == 1 ? 2 : 1

      this.$http
        .put('/api/dep/setStatus/' + row.id, {
          status: row.status
        })
        .then((res) => {
          // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
          if (res.code != 200) {
            //请求错误
            row.status = a
            this.$message.error(res.msg)
          }
          if (res.code == 200) {
            this.$message.success(res.msg)
            // this.close()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    del(row) {
      console.log(row)

      const h = this.$createElement
      this.$msgbox({
        title: '删除',
        message: h('p', null, [h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！')]),
        showCancelButton: true,
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '执行中...'

            // setTimeout(() => {
            //     done();
            //     setTimeout(() => {
            //         instance.confirmButtonLoading = false;
            //     }, 300);
            // }, 3000);

            this.$http
              .del('/api/dep/' + row.id)
              .then((res) => {
                console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', res)

                if (res.code == 200) {
                  this.getList()
                }
                if (res.code != 200) {
                  //请求错误
                  this.$message.error(res.msg)
                }

                // setTimeout(() => {
                done()
                setTimeout(() => {
                  instance.confirmButtonLoading = false
                }, 300)
                // }, 3000);
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            done()
          }
        }
      }).then((action) => {
        // this.$message({
        //     type: 'info',
        //     message: 'action: ' + action
        // });
      })
    },

    toSearch() {
      this.$refs.searchRef.open()
    },
    toSearchChild(row) {
      this.$refs.searchRef.openChild(row)
    },

    modifyLayout() {
      const erd = resizeDetector()

      erd.listenTo(document.getElementsByClassName('search'), (element) => {
        const width = element.offsetWidth
        const height = element.offsetHeight

        // var a = 141 + 60 - 35 + height - 60 - 20;
        // if ($(".level_indexI .FanfuTableBox")[0]) {

        //     $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
        //         'px) !important');

        // }

        // var b = 141 + 60 + 33 + height - 60 - 20;
        // if ($(".level_indexI .el-table")[0]) {

        //     $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
        //         'px) !important');

        // }
      })
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)

      this.data.page = val

      this.getList()
    }
  }
}
</script>





<style lang="scss" scoped>
.el-button--success {
  color: #ffffff;
  background-color: #5bd995;
  border-color: #5bd995;
}

// .level_index.level_indexI {
//     border: 1px blue dotted;
//     box-sizing: border-box;
//     position: relative;
// }
.search {
  // border: 1px blue dotted;
  box-sizing: border-box;

  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
}
</style>


<style scoped>
/*  .level_indexI .FanfuTableBox {
    border: 1px blue dotted;
    box-sizing: border-box;
  } */
/*  .level_indexI .el-table.el-table--fit.el-table--striped.el-table--border.el-table--fluid-height.el-table--scrollable-y.el-table--enable-row-transition.el-table--mini { */

/*  .level_indexI .el-table {
    border: 1px red dotted;
    box-sizing: border-box;
  } */

.operate {
}
</style>